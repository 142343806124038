// Configs
import apisConfig from "../configs/apisConfig";

export const createMedicalRecordFetch = async (
  token,
  patientId,
  medicalRecordName
) => {
  try {
    const queryRoute = "/medicalRecord/createMedicalRecordForPatient";

    const reqBody = {
      patientId,
      medicalRecordName,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteMedicalRecordFetch = async (token, medicalRecordId) => {
  try {
    const queryRoute = `/medicalRecord/deleteMedicalRecordForPatient/${medicalRecordId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editMedicalRecordFetch = async (
  token,
  medicalRecordId,
  medicalRecordName
) => {
  try {
    const queryRoute = `/medicalRecord/editMedicalRecordForPatient/${medicalRecordId}`;

    const reqBody = {
      medicalRecordName,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllMedicalRecordsByPatientIdFetch = async (
  token,
  patientId
) => {
  try {
    const queryRoute = `/medicalRecord/getAllMedicalRecordsByPatientIdForPatient/${patientId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMedicalRecordDetailsByIdFetch = async (
  token,
  medicalRecordId
) => {
  try {
    const queryRoute = `/medicalRecord/getMedicalRecordDetailsByIdForPatient/${medicalRecordId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
