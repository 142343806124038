// Components
// Boxes
import BookingBox from "./bookingBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import BookingActionMenu from "../../menus/bookingRecordsPage/bookingActionMenu";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  cancelBookingFetch,
  getAllBookingsByPatientIdWithPaginationFetch,
} from "../../../fetches/bookingFetches";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/locale/zh-hk";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function BookingBoxesContainer() {
  // Hooks
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const patientId = useSelector((state) => state.patient.id);
  const token = useSelector((state) => state.patient.token);

  // States
  // Data
  const [bookings, setBookings] = useState(null);
  // Dialog
  const [bookingActionDialogText, setBookingActionDialogText] = useState("");
  const [bookingActionDialogType, setBookingActionDialogType] = useState(null);
  const [showBookingActionDialog, setShowBookingActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showBookingActionMenu, setShowBookingActionMenu] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [selectedTherapyId, setSelectedTherapyId] = useState(null);

  // Handle States
  const currentItemId = selectedBookingId;
  const itemsArr = bookings;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    console.log(itemId);
  };

  const onItemRightClicked = (
    itemId,
    therapyId,
    areaId,
    districtId,
    currentTarget
  ) => {
    // Set States
    setSelectedBookingId(itemId);
    setSelectedTherapyId(therapyId);
    setSelectedAreaId(areaId);
    setSelectedDistrictId(districtId);
    setShowBookingActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (
    itemId,
    therapyId,
    areaId,
    districtId,
    currentTarget
  ) => {
    // Set States
    setSelectedBookingId(itemId);
    setSelectedTherapyId(therapyId);
    setSelectedAreaId(areaId);
    setSelectedDistrictId(districtId);
    setShowBookingActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onBookingActionDialogCanceled = () => {
    // Set States
    setShowBookingActionDialog(false);
  };

  const onBookingActionDialogConfirmed = () => {
    switch (bookingActionDialogType) {
      case "CancelBooking":
        cancelBooking();
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const clearAll = () => {
    // Sert States
    setSelectedAreaId(null);
    setSelectedBookingId(null);
    setSelectedDistrictId(null);
    setSelectedTherapyId(null);
  };

  const displayBookingActionDialog = (bookingActionType) => {
    // Set States
    setBookingActionDialogType(bookingActionType);

    switch (bookingActionType) {
      case "CancelBooking":
        setBookingActionDialogText("確認要取消 預約 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(true);
  };

  const navigateToCreateBookingPage = () => {
    // Navigate
    navigate("/createBooking", {
      state: {
        bookingId: selectedBookingId,
        therapyId: selectedTherapyId,
        areaId: selectedAreaId,
        districtId: selectedDistrictId,
      },
    });
  };

  const resetPage = () => {
    // Set States
    setPageNum(1);
  };

  // Functions - Mutations
  const cancelBooking = async () => {
    const results = await cancelBookingFetch(token, currentItemId);

    if (results.success) {
      getAllBookingsByPatientIdWithPagination();
    }
  };

  // Functions - Queries
  const getAllBookingsByPatientIdWithPagination = async () => {
    const results = await getAllBookingsByPatientIdWithPaginationFetch(
      token,
      patientId,
      pageNum,
      limitNum
    );

    if (results.bookings) {
      for (let item of results.bookings) {
        item.date_display = item.date
          ? moment(item.date).format("YYYY年 MM月 DD日")
          : null;

        item.start_time = item.start_time
          ? moment(item.start_time).format("a h:mm")
          : null;

        item.end_time = item.end_time
          ? moment(item.end_time).format("a h:mm")
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setBookings(results.bookings ? results.bookings : null);

    if (results.pageCount && !results.bookings) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    resetPage();
  }, []);

  useEffect(() => {
    getAllBookingsByPatientIdWithPagination();
  }, [patientId, pageNum]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onBookingActionDialogCanceled}
        onDialogConfirmed={onBookingActionDialogConfirmed}
        // States
        dialogText={bookingActionDialogText}
        showDialog={showBookingActionDialog}
      />
      {/* Menu */}
      <BookingActionMenu
        // States
        showBookingActionMenu={showBookingActionMenu}
        // Set States
        setShowBookingActionMenu={setShowBookingActionMenu}
        // Fucntions
        clearAll={clearAll}
        displayBookingActionDialog={displayBookingActionDialog}
        navigateToCreateBookingPage={navigateToCreateBookingPage}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            預約
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <BookingBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有預約
          </Typography>
        </div>
      )}
    </div>
  );
}

export default BookingBoxesContainer;
