const initialState = {
  tabItem: "BookingRecords",
};

export const bookingRecordsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_BOOKING_RECORDS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
