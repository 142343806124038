export const createInitialMedicalRecordItem = () => {
  return {
    id: 1,
    MedicalRecordName: "",
  };
};

export const createMedicalRecordItem = (currentMedicalRecords) => {
  const itemId = currentMedicalRecords[0]
    ? currentMedicalRecords[currentMedicalRecords.length - 1].id + 1
    : 1;

  return {
    id: itemId,
    MedicalRecordName: "",
  };
};
