// Configs
import apisConfig from "../configs/apisConfig";

export const autoLoginFetch = async (token) => {
  try {
    const queryRoute = "/patient/autoLogin";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createPatientFetch = async (
  hkid,
  genderTypeId,
  fullNameCh,
  fullNameEn,
  dateBirth,
  email,
  phoneNum,
  districtId,
  address,
  medicalRecords
) => {
  try {
    const queryRoute = "/patient/createPatient";

    const reqBody = {
      hkid,
      genderTypeId,
      fullNameCh,
      fullNameEn,
      dateBirth,
      email,
      phoneNum,
      districtId,
      address,
      medicalRecords,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getPatientBasicInfoByIdFetch = async (token, patientId) => {
  try {
    const queryRoute = `/patient/getPatientBasicInfoByIdForPatients/${patientId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const loginFetch = async (hkid, fullNameEn) => {
  try {
    const queryRoute = "/patient/login";

    const reqBody = {
      hkid,
      fullNameEn,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
