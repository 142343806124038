// Redux
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducers
// Pages
import { bookingRecordsPageReducers } from "./pages/bookingRecordsPage/bookingRecordsPageReducers";
import { createBookingPageReducers } from "./pages/createBookingPage/createBookingPageReducers";
import { medicalRecordsPageReducers } from "./pages/medicalRecordsPage/medicalRecordsPageReducers";
import { patientRegisterPageReducers } from "./pages/patientRegisterPage/patientRegisterPageReducers";
// Shared
import { drawerReducers } from "./shared/drawer/drawerReducers";
import { locationReducers } from "./shared/location/locationReducers";
import { patientReducers } from "./shared/patient/patientReducers";
import { themeReducers } from "./shared/theme/themeReducers";

const rootReducer = combineReducers({
  // Pages
  bookingRecordsPage: bookingRecordsPageReducers,
  createBookingPage: createBookingPageReducers,
  medicalRecordsPage: medicalRecordsPageReducers,
  patientRegisterPage: patientRegisterPageReducers,
  // Shared
  drawer: drawerReducers,
  location: locationReducers,
  patient: patientReducers,
  theme: themeReducers,
});

export const store = configureStore({ reducer: rootReducer });
