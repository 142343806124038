// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function MedicalRecordBox(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    index,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(item.id, event.currentTarget);
        }}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.blueTextColor : null}
            variant="body1"
          >
            {item.medical_record_name}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {`病歷 ${index + 1}`}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) =>
            onItemShowMenuBtnClicked(item.id, event.currentTarget)
          }
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default MedicalRecordBox;
