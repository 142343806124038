// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function PatientActionModalPatientInfo(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainer}>
          <Typography align={"left"} variant="body1">
            {item.hkid}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.full_name_en}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default PatientActionModalPatientInfo;
