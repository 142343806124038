// Actions
import { closeDrawer } from "../../redux/shared/drawer/drawerActions";

// Components
// List Item
import LeftDrawerListItem from "./leftDrawerListItem";

// Configs
import stylesConfig from "../../configs/stylesConfig";

// Helper Functions
import { getPagePath } from "../../helperFunctions/getPagePath";

// Material UI
// Components
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// Icons
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import { dividerStyles } from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerList() {
  // Hooks
  // React-Router
  const location = useLocation();
  const navigate = useNavigate();
  // Redux
  const dispatch = useDispatch();

  // Events
  // Events - Drawer Items
  const onDrawerItemClicked = (key) => {
    const path = getPagePath(key);

    // Navigate
    navigate(path);

    // Update Redux Store
    dispatch(closeDrawer());
  };

  return (
    <List>
      <ListItem sx={{ height: stylesConfig.leftDrawerHeight }}>
        <ListItemText primary={"預約系統"} />
      </ListItem>
      <Divider sx={dividerStyles} />
      {/* 新增預約 */}
      <LeftDrawerListItem
        // Render
        icon={<PostAddRoundedIcon />}
        itemKey={"CreateBooking"}
        location={location}
        pathnamesArr={["/createBooking"]}
        listItemText={"新增預約"}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 預約紀錄 */}
      <LeftDrawerListItem
        // Render
        icon={<AssignmentIndRoundedIcon />}
        itemKey={"BookingRecords"}
        location={location}
        pathnamesArr={["/bookingRecords"]}
        listItemText={"預約紀錄"}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 病歷 */}
      <LeftDrawerListItem
        // Render
        icon={<AssignmentRoundedIcon />}
        itemKey={"MedicalRecords"}
        location={location}
        pathnamesArr={["/medicalRecords"]}
        listItemText={"病歷"}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
    </List>
  );
}

export default LeftDrawerList;
