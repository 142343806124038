// Pages
import BookingRecordsPage from "./pages/bookingRecordsPage";
import CreateBookingPage from "./pages/createBookingPage";
import LoginPage from "./pages/loginPage";
import MedicalRecordsPage from "./pages/medicalRecordsPage";
import PatientRegisterPage from "./pages/patientRegisterPage";

// React-Redux
import { useSelector } from "react-redux";

// React-Router
import { Navigate, Route, Routes } from "react-router-dom";

function Router() {
  // Redux Store
  const token = useSelector((state) => state.patient.token);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/bookingRecords"
        element={token ? <BookingRecordsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/createBooking"
        element={token ? <CreateBookingPage /> : <Navigate to="/" />}
      />
      <Route
        path="/medicalRecords"
        element={token ? <MedicalRecordsPage /> : <Navigate to="/" />}
      />
      <Route path="/patientRegister" element={<PatientRegisterPage />} />
    </Routes>
  );
}

export default Router;
