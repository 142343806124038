// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/medicalRecordsPage/medicalRecordsPageActions";

// Components
// Boxes
import MedicalRecordBox from "./medicalRecordBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import MedicalRecordActionMenu from "../../menus/medicalRecordsPage/medicalRecordActionMenu";
// Modals
import MedicalRecordActionModal from "../../modals/medicalRecordsPage/medicalRecordActionModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  deleteMedicalRecordFetch,
  getAllMedicalRecordsByPatientIdFetch,
} from "../../../fetches/medicalRecordFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MedicalRecordBoxesContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const patientId = useSelector((state) => state.patient.id);
  const formSubmitCount = useSelector(
    (state) => state.medicalRecordsPage.formSubmitCount
  );
  const token = useSelector((state) => state.patient.token);

  // States
  // Data
  const [medicalRecords, setMedicalRecords] = useState(null);
  // Dialog
  const [medicalRecordActionDialogText, setMedicalRecordActionDialogText] =
    useState("");
  const [medicalRecordActionDialogType, setMedicalRecordActionDialogType] =
    useState(null);
  const [showMedicalRecordActionDialog, setShowMedicalRecordActionDialog] =
    useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showMedicalRecordActionMenu, setShowMedicalRecordActionMenu] =
    useState(null);
  // Modals
  const [showMedicalRecordActionModal, setShowMedicalRecordActionModal] =
    useState(false);

  // Handle States
  const itemsArr = medicalRecords;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayMedicalRecordActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowMedicalRecordActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowMedicalRecordActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onMedicalRecordActionDialogCanceled = () => {
    // Set States
    setShowMedicalRecordActionDialog(false);
  };

  const onMedicalRecordActionDialogConfirmed = () => {
    switch (medicalRecordActionDialogType) {
      case "DeleteMedicalRecord":
        deleteMedicalRecord();
        break;
      default:
        break;
    }

    // Set States
    setShowMedicalRecordActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onMedicalRecordActionModalClosed = () => {
    // Set States
    setShowMedicalRecordActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayMedicalRecordActionModal();
  };

  // Functions
  // Functions - Normal
  const displayMedicalRecordActionDialog = (medicalRecordActionType) => {
    // Set States
    setMedicalRecordActionDialogType(medicalRecordActionType);

    switch (medicalRecordActionType) {
      case "DeleteMedicalRecord":
        setMedicalRecordActionDialogText("確認要刪除 病歷 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowMedicalRecordActionDialog(true);
  };

  const displayMedicalRecordActionModal = () => {
    setShowMedicalRecordActionModal(true);
  };

  // Functions - Mutations
  const deleteMedicalRecord = async () => {
    const results = await deleteMedicalRecordFetch(token, currentItemId);

    if (results.success) {
      getAllMedicalRecordsByPatientId();
    }
  };

  // Functions - Queries
  const getAllMedicalRecordsByPatientId = async () => {
    const results = await getAllMedicalRecordsByPatientIdFetch(
      token,
      patientId
    );

    // Set States
    setMedicalRecords(results.medicalRecords ? results.medicalRecords : null);
  };

  // Life Cycle
  useEffect(() => {
    if (patientId) {
      getAllMedicalRecordsByPatientId();
    }
  }, [formSubmitCount, patientId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMedicalRecordActionDialogCanceled}
        onDialogConfirmed={onMedicalRecordActionDialogConfirmed}
        // States
        dialogText={medicalRecordActionDialogText}
        showDialog={showMedicalRecordActionDialog}
      />
      {/* Menu */}
      <MedicalRecordActionMenu
        // States
        showMedicalRecordActionMenu={showMedicalRecordActionMenu}
        // Set States
        setShowMedicalRecordActionMenu={setShowMedicalRecordActionMenu}
        // Functions
        displayMedicalRecordActionDialog={displayMedicalRecordActionDialog}
        displayMedicalRecordActionModal={displayMedicalRecordActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onMedicalRecordActionModalClosed}
        // States
        showModal={showMedicalRecordActionModal}
      >
        <MedicalRecordActionModal
          // Events
          onModalClosed={onMedicalRecordActionModalClosed}
          // States
          medicalRecordId={currentItemId}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            病歷
          </Typography>
          <Tooltip placement="right" title="新增病歷">
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <MedicalRecordBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            index={index}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有病歷資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MedicalRecordBoxesContainer;
