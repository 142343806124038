const initialState = {
  token: null,
  fullNameEn: null,
  id: null,
};

export const patientReducers = (state = initialState, action) => {
  switch (action.type) {
    case "PATIENT_LOGIN":
      const { token, fullNameEn, id } = action;

      return {
        token,
        fullNameEn,
        id,
      };
    case "PATIENT_LOGOUT":
      return {
        token: null,
        fullNameEn: null,
        id: null,
      };
    default:
      return state;
  }
};
