// Components
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MedicalRecordBox(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // Render
    shouldShowSpacing,
    // States
    index,
    item,
  } = props;

  // Handle States
  const itemId = item.id;

  return (
    <>
      {shouldShowSpacing && (
        <SpacingBox
          // Render
          height={stylesConfig.spacingBoxMarginBottom}
        />
      )}
      <div className={classes.contentBox}>
        {/* Title */}
        <div className={classes.titleContainer}>
          <div className={classes.titleTextContainer}>
            <Typography variant="h6" align={"left"}>
              病歷 {index + 1}
            </Typography>
          </div>
          <div className={classes.titleTextContainer}>
            {checkIsDynamicFieldError(
              "medicalRecord",
              itemId,
              "medicalRecordName"
            ) ? (
              <Button
                endIcon={<DeleteRoundedIcon />}
                onClick={() =>
                  onDeleteDynamicFieldBtnClicked("medicalRecord", itemId)
                }
                sx={{ color: stylesConfig.redTextColor }}
                variant="text"
              >
                如不需填寫病歷，請按此刪除
              </Button>
            ) : (
              <Tooltip placement="right" title="刪除病歷">
                <IconButton
                  onClick={() =>
                    onDeleteDynamicFieldBtnClicked("medicalRecord", itemId)
                  }
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {/* Form */}
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "medicalRecord",
                itemId,
                "medicalRecordName"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "medicalRecord",
                itemId,
                "medicalRecordName"
              )}
              label="病歷名稱"
              margin="dense"
              name="medicalRecordName"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "medicalRecord",
                  itemId,
                  "medicalRecordName",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.medicalRecordName}
              variant="standard"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default MedicalRecordBox;
