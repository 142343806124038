// Material UI
// Components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PatientRegisterFormContactContent(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    email,
    phoneNum,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            聯絡方式
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("phoneNum")}
            fullWidth
            helperText={getErrorFieldMessage("phoneNum")}
            label="電話號碼"
            margin="dense"
            name="phoneNum"
            onChange={(event) =>
              onInputFieldChange("phoneNum", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={phoneNum}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("email")}
            fullWidth
            helperText={getErrorFieldMessage("email")}
            label="電郵地址"
            margin="dense"
            name="email"
            onChange={(event) =>
              onInputFieldChange("email", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={email}
            variant="standard"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default PatientRegisterFormContactContent;
