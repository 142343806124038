// Configs
import apisConfig from "../configs/apisConfig";

export const cancelBookingFetch = async (token, bookingId) => {
  try {
    const queryRoute = `/booking/cancelBookingForPatients/${bookingId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createBookingFetch = async (
  token,
  roomId,
  patientId,
  therapyId,
  date,
  startTimeSlotId
) => {
  try {
    const queryRoute = "/booking/createBookingForPatients";

    const reqBody = {
      roomId,
      patientId,
      therapyId,
      date,
      startTimeSlotId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editBookingFetch = async (
  token,
  bookingId,
  roomId,
  therapyId,
  date,
  startTimeSlotId
) => {
  try {
    const queryRoute = `/booking/editBookingForPatients/${bookingId}`;

    const reqBody = {
      roomId,
      therapyId,
      date,
      startTimeSlotId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllBookingsByPatientIdWithPaginationFetch = async (
  token,
  patientId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/booking/getAllBookingsByPatientIdWithPaginationForPatients/${patientId}`;

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
