export const getPagePath = (titleType) => {
  let path;

  switch (titleType) {
    case "BookingRecords":
      path = "/bookingRecords";
      break;
    case "CreateBooking":
      path = "/createBooking";
      break;
    case "MedicalRecords":
      path = "/medicalRecords";
      break;
    default:
      path = "/";
      break;
  }

  return path;
};
