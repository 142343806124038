// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import MedicalRecordBoxesContainer from "../components/boxes/medicalRecordsPage/medicalRecordBoxesContainer";
// Headers
import MedicalRecordsPageHeader from "../components/headers/medicalRecordsPage/medicalRecordsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

function MedicalRecordsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("MedicalRecords"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <MedicalRecordsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={0} lg={2}></Grid>
        <Grid item xs={12} lg={8}>
          <MedicalRecordBoxesContainer />
        </Grid>
        <Grid item xs={0} lg={2}></Grid>
      </Grid>
    </Container>
  );
}

export default MedicalRecordsPage;
