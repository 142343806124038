// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const useHorizontalItemBoxStyles = makeStyles((theme) =>
  createStyles({
    hoveredItemContainer: {
      alignItems: "flex-start",
      borderBottomStyle: "solid",
      borderColor: stylesConfig.greyBackGroundColor,
      borderWidth: 1,
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 40px 4px 5px",
      padding: "5px 5px",
    },
    itemContainer: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 35px 5px 0px",
      padding: "5px 10px",
    },
    itemContentContainer: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    itemContentSubContainer: {
      flex: 1,
    },
    itemContentSubContainerWithUpperMargin: {
      flex: 1,
      marginLeft: "5px",
      marginTop: "5px",
    },
    selectedItemContainer: {
      alignItems: "flex-start",
      backgroundColor: stylesConfig.blueTextColor,
      borderRadius: stylesConfig.borderRadiusExtraSmall,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 35px 5px 0px",
      padding: "5px 10px",
    },
  })
);
