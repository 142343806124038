// Actions
import { onShopChange } from "../../../redux/pages/createBookingPage/createBookingPageActions";

// Components
// Boxes
import ShopSuggestionBox from "./shopSuggestionBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getShopSuggestionsFetch } from "../../../fetches/shopFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useHorizontalItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxesContainerStyles";

function ShopSuggestionBoxesContainer() {
  // Hooks
  // Styles
  const classes = useHorizontalItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const districtId = useSelector((state) => state.createBookingPage.districtId);
  const shopId = useSelector((state) => state.createBookingPage.shopId);
  const therapyId = useSelector((state) => state.createBookingPage.therapyId);
  const token = useSelector((state) => state.patient.token);

  // States
  // Data
  const [shops, setShops] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = shopId;
  const itemsArr = shops;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onShopChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Queries
  const getShopSuggestions = async () => {
    const results = await getShopSuggestionsFetch(token, therapyId, districtId);

    // Set States
    setShops(results.shops ? results.shops : null);

    if (
      results.shops &&
      shopId &&
      results.shops.findIndex((item) => item.id === shopId) !== -1
    ) {
      return;
    }

    // Update Redux Store
    dispatch(onShopChange(null));
  };

  // Life Cycle
  useEffect(() => {
    if (therapyId && districtId) {
      getShopSuggestions();
    } else {
      // Update Redux Store
      dispatch(onShopChange(null));
    }
  }, [therapyId, districtId]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            店舖
          </Typography>
        </div>
      </div>
      {itemsArr && itemsArr[0] ? (
        <div className={classes.itemBoxesContainer}>
          {itemsArr.map((item) => (
            <ShopSuggestionBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有店舖可進行此療程
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ShopSuggestionBoxesContainer;
