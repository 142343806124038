export const getPageTitleText = (titleType) => {
  let titleText;

  switch (titleType) {
    case "BookingRecords":
      titleText = "預約紀錄";
      break;
    case "CreateBooking":
      titleText = "新增預約";
      break;
    case "MedicalRecords":
      titleText = "病歷";
      break;
    default:
      titleText = "";
      break;
  }

  return titleText;
};
