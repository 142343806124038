// Material UI
// Components
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardListPageStyles } from "../../../styles/pageStyles/standardListPageStyles";

function PatientRegisterPageHeader() {
  // Hooks
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const activeStep = useSelector(
    (state) => state.patientRegisterPage.activeStep
  );

  return (
    <Box className={classes.divisionsContainer}>
      {/* Stepper */}
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepperContainer}
      >
        <Step>
          <StepLabel>基本資料</StepLabel>
        </Step>
        <Step>
          <StepLabel>聯絡方式 / 住宅地址</StepLabel>
        </Step>
        <Step>
          <StepLabel>病歷</StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}

export default PatientRegisterPageHeader;
