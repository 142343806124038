// Actions
import { showDrawer } from "../../redux/shared/drawer/drawerActions";
import { onThemeChange } from "../../redux/shared/theme/themeActions";

// Components
// Modals
import PatientActionModal from "../modals/topAppBar/patientActionModal";
import ModalContainer from "../modals/modalContainer";

// Configs
import keyConfig from "../../configs/keyConfig";
import stylesConfig from "../../configs/stylesConfig";

// Helper Functions
import { getPagePath } from "../../helperFunctions/getPagePath";
import { getPageTitleText } from "../../helperFunctions/getPageTitleText";

// Material UI
// Components
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
// Icons
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Brightness4RoundedIcon from "@mui/icons-material/Brightness4Rounded";
import Brightness7RoundedIcon from "@mui/icons-material/Brightness7Rounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  appBarContainerStyles,
  showMenuBtnStyles,
  useAppBarStyles,
} from "../../styles/componentStyles/appBarStyles/appBarStyles";

function TopAppBar() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useAppBarStyles();

  // Redux Store
  const previousPage = useSelector((state) => state.location.previousPage);
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Modals
  const [showPatientActionModal, setShowPatientActionModal] = useState(false);

  // Handle States
  const previousPageTitleText = getPageTitleText(previousPage);

  // Events
  // Events - Btns
  const onAccountInfoBtnClicked = () => {
    displayPatientActionModal();
  };

  const onBackBtnClicked = () => {
    const path = getPagePath(previousPage);

    // Navigate
    navigate(path);
  };

  const onShowDrawerBtnClicked = () => {
    // Update Redux Store
    dispatch(showDrawer("left"));
  };

  const onToggleThemeBtnClicked = () => {
    const themeModeVar = themeMode === "light" ? "dark" : "light";

    // Update Redux Store
    dispatch(onThemeChange(themeModeVar));

    storeThemeModeToStorage(themeModeVar);
  };

  // Events - Modal
  const onPatientActionModalClosed = () => {
    // Set States
    setShowPatientActionModal(false);
  };

  // Functions
  // Functions - Normal
  const displayPatientActionModal = () => {
    setShowPatientActionModal(true);
  };

  const storeThemeModeToStorage = (themeModeVar) => {
    // Local Storage
    localStorage.setItem(keyConfig.themeKey, themeModeVar);
  };

  return (
    <AppBar
      sx={{
        ...appBarContainerStyles,
        backgroundColor:
          themeMode === "light"
            ? stylesConfig.appBarContainerBackgroundColorLight
            : stylesConfig.appBarContainerBackgroundColorDark,
      }}
    >
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onPatientActionModalClosed}
        // States
        showModal={showPatientActionModal}
      >
        <PatientActionModal
          // Events
          onModalClosed={onPatientActionModalClosed}
        />
      </ModalContainer>
      <Toolbar className={classes.contentContainer} disableGutters>
        <div className={classes.rightContainer}>
          <Tooltip sx={showMenuBtnStyles} title={"顯示選單"}>
            <IconButton onClick={onShowDrawerBtnClicked}>
              <MenuRoundedIcon />
            </IconButton>
          </Tooltip>
          {previousPage ? (
            <Button
              onClick={onBackBtnClicked}
              startIcon={<ArrowBackIosRoundedIcon />}
              variant="text"
            >
              {previousPageTitleText}
            </Button>
          ) : (
            <div className={classes.spaceHolder} />
          )}
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.searchBtnContainer}>
            <Tooltip
              title={
                themeMode === "light" ? "設換至夜間模式" : "設換至日間模式"
              }
            >
              <IconButton onClick={onToggleThemeBtnClicked}>
                {themeMode === "light" ? (
                  <Brightness4RoundedIcon />
                ) : (
                  <Brightness7RoundedIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.selfInfoContainer}>
            <Tooltip title={"帳戶資料"}>
              <IconButton onClick={onAccountInfoBtnClicked}>
                <AccountCircleRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default TopAppBar;
