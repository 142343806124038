const initialState = {
  themeMode: "dark",
};

export const themeReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_THEME_CHANGE":
      const { themeMode } = action;
      return {
        ...state,
        themeMode,
      };
    default:
      return state;
  }
};
