// Actions
import {
  onActiveStepDecrease,
  onActiveStepIncrease,
  onActiveStepReset,
} from "../../../redux/pages/patientRegisterPage/patientRegisterPageActions";
import { patientLogin } from "../../../redux/shared/patient/patientActions";

// Components
// Boxes
import MedicalRecordBoxesContainer from "../../boxes/patientRegisterPage/medicalRecordBoxesContainer";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import PatientRegisterFormAddressContent from "./patientRegisterFormAddressContent";
import PatientRegisterFormBasicInfoContent from "./patientRegisterFormBasicInfoContent";
import PatientRegisterFormContactContent from "./patientRegisterFormContactContent";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import keyConfig from "../../../configs/keyConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  createPatientFetch,
  loginFetch,
} from "../../../fetches/patientFetches";
import { getPatientApplicationFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Helper Functions
import {
  createInitialMedicalRecordItem,
  createMedicalRecordItem,
} from "../../../helperFunctions/createMedicalRecordItem";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function PatientRegisterFormContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Redux Store
  const activeStep = useSelector(
    (state) => state.patientRegisterPage.activeStep
  );

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [dateBirth, setDateBirth] = useState(null);
  // Dialog
  const [patientRegisterActionDialogText, setPatientRegisterActionDialogText] =
    useState("");
  const [patientRegisterActionDialogType, setPatientRegisterActionDialogType] =
    useState(null);
  const [showPatientRegisterActionDialog, setShowPatientRegisterActionDialog] =
    useState(false);
  // Dynamic Fields
  const [medicalRecords, setMedicalRecords] = useState([
    createInitialMedicalRecordItem(),
  ]);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  const [dynamicErrorFields, setDynamicErrorFields] = useState([]);
  // Options Fields
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [genderTypeField, setGenderTypeField] = useState(null);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [hkid, setHkid] = useState("");
  const [fullNameCh, setFullNameCh] = useState("");
  const [fullNameEn, setFullNameEn] = useState("");
  const [phoneNum, setPhoneNum] = useState("");

  // Events
  // Events - Dialogs
  const onPatientRegisterActionDialogCanceled = () => {
    // Set States
    setShowPatientRegisterActionDialog(false);
  };

  const onPatientRegisterActionDialogConfirmed = () => {
    switch (patientRegisterActionDialogType) {
      case "SubmissionFailed":
        break;
      case "SubmissionSucceed":
        login();
        break;
      case "SubmitApplication":
        createPatient();
        break;
      default:
        break;
    }

    // Set States
    setShowPatientRegisterActionDialog(false);
  };

  // Events - Fields
  const onDynamicInputFieldChange = (field, id, subField, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "medicalRecord":
        setMedicalRecords((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;
            }
          }

          return tempState;
        });
        break;
      default:
        break;
    }

    removeDynamicErrorField(field, id, subField);
  };

  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "address":
        setAddress(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          districtOptions.find((item) => item.area_id === value.id)
        );

        removeErrorField("districtField");

        break;
      case "dateBirth":
        setDateBirth(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "hkid":
        setHkid(value);
        break;
      case "fullNameCh":
        setFullNameCh(value);
        break;
      case "fullNameEn":
        setFullNameEn(value);
        break;
      case "phoneNum":
        setPhoneNum(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      switch (activeStep) {
        case 0:
          onPatientRegisterFormBasicInfoContentSubmitBtnClicked();
          break;
        case 1:
          onPatientRegisterFormContactAddressContentSubmitBtnClicked();
          break;
        case 2:
          onPatientRegisterFormMedicalRecordContentSubmitBtnClicked();
          break;
        default:
          break;
      }
    }
  };

  // Events - Forms
  const onAddDynamicFieldBtnClicked = (field) => {
    // Set States
    switch (field) {
      case "medicalRecord":
        setMedicalRecords((currentState) => [
          ...currentState,
          createMedicalRecordItem(currentState),
        ]);

        break;
      default:
        break;
    }
  };

  const onDeleteDynamicFieldBtnClicked = (field, id) => {
    // Set States
    switch (field) {
      case "medicalRecord":
        setMedicalRecords((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      default:
        break;
    }

    removeAllDynamicErrorField(field, id);
  };

  const onPatientRegisterFormBasicInfoContentSubmitBtnClicked = () => {
    let isError = false;

    if (!dateBirth) {
      addToErrorFields("dateBirth", "請先填寫 出生日期");
      isError = true;
    }

    if (!genderTypeField) {
      addToErrorFields("genderTypeField", "請先填寫 性別");
      isError = true;
    }

    if (!hkid) {
      addToErrorFields("hkid", "請先填寫 身分證編號");
      isError = true;
    }

    if (!fullNameEn) {
      addToErrorFields("fullNameEn", "請先填寫 英文全名");
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onPatientRegisterFormContactAddressContentSubmitBtnClicked = () => {
    let isError = false;

    if (!phoneNum) {
      addToErrorFields("phoneNum", "請先填寫 電話號碼");
      isError = true;
    }

    if (!areaField) {
      addToErrorFields("areaField", "請先填寫 區域");
      isError = true;
    }

    if (!districtField) {
      addToErrorFields("districtField", "請先填寫 地區");
      isError = true;
    }

    if (!address) {
      addToErrorFields("address", "請先填寫 地址");
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onPatientRegisterFormMedicalRecordContentSubmitBtnClicked = () => {
    let isError = false;

    for (let item of medicalRecords) {
      if (!item.medicalRecordName) {
        addToDynamicErrorFields(
          "medicalRecord",
          item.id,
          "medicalRecordName",
          "請先填寫 病歷名稱"
        );
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayPatientRegisterActionDialog("SubmitApplication");
  };

  const onStepBackBtnClicked = () => {
    if (activeStep !== 0) {
      // Update Redux Store
      dispatch(onActiveStepDecrease());
    } else {
      // Navigate
      navigate("/");
    }
  };

  // Functions
  // Functions - Normal
  const addToDynamicErrorFields = (field, id, subField, message) => {
    if (
      dynamicErrorFields.some(
        (item) =>
          item.field === field && item.id === id && item.subField === subField
      )
    ) {
      return;
    }

    // Set States
    setDynamicErrorFields((currentState) => [
      ...currentState,
      { field, id, subField, message },
    ]);
  };

  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsDynamicFieldError = (field, id, subField) => {
    return dynamicErrorFields.some(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const displayPatientRegisterActionDialog = (patientRegisterActionType) => {
    // Set States
    setPatientRegisterActionDialogType(patientRegisterActionType);

    switch (patientRegisterActionType) {
      case "SubmissionFailed":
        setPatientRegisterActionDialogText("未能提交，請再試一次");
        break;
      case "SubmissionSucceed":
        setPatientRegisterActionDialogText("已成功提交，請開始預約門診。");
        break;
      case "SubmitApplication":
        setPatientRegisterActionDialogText("確認要提交 註冊申請 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowPatientRegisterActionDialog(true);
  };

  const getDynamicErrorFieldMessage = (field, id, subField) => {
    const targetField = dynamicErrorFields.find(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeAllDynamicErrorField = (field, id) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field || item.id !== id)
    );
  };

  const removeDynamicErrorField = (field, id, subField) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter(
        (item) =>
          item.field !== field || item.id !== id || item.subField !== subField
      )
    );
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const resetAll = () => {
    // Set States
    // Date Fields
    setDateBirth(null);
    // Dynamic Fields
    setMedicalRecords([createInitialMedicalRecordItem()]);
    // Error Fields
    setErrorFields([]);
    setDynamicErrorFields([]);
    // Options Fields
    setAreaField(null);
    setDistrictField(null);
    setGenderTypeField(null);
    // Text Fields
    setAddress("");
    setEmail("");
    setHkid("");
    setFullNameCh("");
    setFullNameEn("");
    setPhoneNum("");

    // Update Redux Store
    dispatch(onActiveStepReset());
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  const updatePatientInfo = (patient, token) => {
    // Update Redux Store
    dispatch(patientLogin(token, patient.full_name_en, patient.id));

    // Local Storage
    localStorage.setItem(keyConfig.tokenKey, token);
  };

  // Functions - Mutations
  const createPatient = async () => {
    const results = await createPatientFetch(
      hkid,
      genderTypeField ? genderTypeField.id : null,
      fullNameCh,
      fullNameEn,
      dateBirth,
      email,
      phoneNum,
      districtField ? districtField.id : null,
      address,
      medicalRecords
    );

    if (results.success) {
      displayPatientRegisterActionDialog("SubmissionSucceed");
    } else if (results.isDuplicate) {
      showFormAlert("warning", "此 身份證編號 已被登記");
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  // Functions - Queries
  const getPatientApplicationFormOptions = async () => {
    const results = await getPatientApplicationFormOptionsFetch();

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
  };

  const login = async () => {
    const results = await loginFetch(hkid, fullNameEn);

    if (results.token && results.patient) {
      const { patient, token } = results;

      updatePatientInfo(patient, token);

      // Navigate
      navigate("/createBooking");
    } else {
      resetAll();

      // Navigate
      navigate("/");
    }
  };

  // Life Cycle
  useEffect(() => {
    getPatientApplicationFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    // Set States
    setShouldShowFormAlert(false);
  }, [activeStep]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPatientRegisterActionDialogCanceled}
        onDialogConfirmed={onPatientRegisterActionDialogConfirmed}
        // States
        dialogText={patientRegisterActionDialogText}
        showDialog={showPatientRegisterActionDialog}
      />
      {activeStep === 0 && (
        <PatientRegisterFormBasicInfoContent
          // States
          dateBirth={dateBirth}
          genderTypeField={genderTypeField}
          genderTypeOptions={genderTypeOptions}
          hkid={hkid}
          fullNameCh={fullNameCh}
          fullNameEn={fullNameEn}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onPatientRegisterFormBasicInfoContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
      {activeStep === 1 && (
        <>
          <PatientRegisterFormContactContent
            // States
            email={email}
            phoneNum={phoneNum}
            // Events
            onInputFieldChange={onInputFieldChange}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            // Functions
            checkIsFieldError={checkIsFieldError}
            getErrorFieldMessage={getErrorFieldMessage}
          />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
          <PatientRegisterFormAddressContent
            // States
            address={address}
            areaField={areaField}
            areaOptions={areaOptions}
            districtField={districtField}
            districtOptions={districtOptions}
            // Events
            onInputFieldChange={onInputFieldChange}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            onNextStepBtnClicked={
              onPatientRegisterFormContactAddressContentSubmitBtnClicked
            }
            onStepBackBtnClicked={onStepBackBtnClicked}
            // Functions
            checkIsFieldError={checkIsFieldError}
            getErrorFieldMessage={getErrorFieldMessage}
          />
        </>
      )}
      {activeStep === 2 && (
        <MedicalRecordBoxesContainer
          // Events
          onAddDynamicFieldBtnClicked={onAddDynamicFieldBtnClicked}
          onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
          onDynamicInputFieldChange={onDynamicInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onPatientRegisterFormMedicalRecordContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsDynamicFieldError={checkIsDynamicFieldError}
          getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
          // States
          formAlertText={formAlertText}
          formAlertType={formAlertType}
          medicalRecords={medicalRecords}
          shouldShowFormAlert={shouldShowFormAlert}
        />
      )}
    </>
  );
}

export default PatientRegisterFormContainer;
