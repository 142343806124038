// Actions
import {
  onAreaChange,
  onAreaItemChange,
  onDistrictChange,
  onDistrictItemChange,
  onTherapyChange,
  onTherapyItemChange,
} from "../../../redux/pages/createBookingPage/createBookingPageActions";

// Fetches
import { getCreateBookingFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function BookingConditionBoxesContainer(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Props
  const {
    // States
    areaIdParam,
    districtIdParam,
    therapyIdParam,
  } = props;

  // Redux Store
  const areaId = useSelector((state) => state.createBookingPage.areaId);
  const areaItem = useSelector((state) => state.createBookingPage.areaItem);
  const districtId = useSelector((state) => state.createBookingPage.districtId);
  const districtItem = useSelector(
    (state) => state.createBookingPage.districtItem
  );
  const therapyId = useSelector((state) => state.createBookingPage.therapyId);
  const therapyItem = useSelector(
    (state) => state.createBookingPage.therapyItem
  );
  const token = useSelector((state) => state.patient.token);

  // States
  // Data
  const [areas, setAreas] = useState(null);
  const [districts, setDistricts] = useState(null);
  const [therapies, setTherapies] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "area":
        dispatch(onAreaChange(value.id));
        dispatch(
          onDistrictChange(
            districts.find((item) => item.area_id === value.id).id
          )
        );
        break;
      case "district": {
        dispatch(onDistrictChange(value.id));
        break;
      }
      case "therapy": {
        dispatch(onTherapyChange(value.id));
        break;
      }
      default:
        break;
    }
  };

  // Functions
  // Functions - Queries
  const getCreateBookingFormOptions = async () => {
    const results = await getCreateBookingFormOptionsFetch(token);

    // Set States
    setAreas(results.areas ? results.areas : null);
    setDistricts(results.districts ? results.districts : null);
    setTherapies(results.therapies ? results.therapies : null);

    // Update Redux Store
    if (
      areaIdParam &&
      results.areas &&
      results.areas.some((item) => item.id === areaIdParam)
    ) {
      dispatch(onAreaChange(areaIdParam));
    }

    if (
      districtIdParam &&
      results.districts &&
      results.districts.some((item) => item.id === districtIdParam)
    ) {
      dispatch(onDistrictChange(districtIdParam));
    }

    if (
      therapyIdParam &&
      results.therapies &&
      results.therapies.some((item) => item.id === therapyIdParam)
    ) {
      dispatch(onTherapyChange(therapyIdParam));
    }
  };

  // Life Cycle
  useEffect(() => {
    getCreateBookingFormOptions();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (areas && areaId) {
      dispatch(onAreaItemChange(areas.find((item) => item.id === areaId)));
    }
  }, [areas, areaId]);

  useEffect(() => {
    // Update Redux Store
    if (districts && districtId) {
      dispatch(
        onDistrictItemChange(districts.find((item) => item.id === districtId))
      );
    }
  }, [districts, districtId]);

  useEffect(() => {
    // Update Redux Store
    if (therapies && therapyId) {
      dispatch(
        onTherapyItemChange(therapies.find((item) => item.id === therapyId))
      );
    }
  }, [therapies, therapyId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            療程
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {therapies && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.therapy_name}
            onChange={(event, value) => onInputFieldChange("therapy", value)}
            options={therapies}
            renderInput={(params) => (
              <TextField {...params} label="療程" variant="outlined" />
            )}
            value={therapyItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            區域
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {areas && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.area_name_ch}
            onChange={(event, value) => onInputFieldChange("area", value)}
            options={areas}
            renderInput={(params) => (
              <TextField {...params} label="區域" variant="outlined" />
            )}
            value={areaItem}
          />
        </div>
      )}
      {areaId && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                地區
              </Typography>
            </div>
          </div>
          {/* Select Box */}
          {districts && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) => option.district_name_ch}
                onChange={(event, value) =>
                  onInputFieldChange("district", value)
                }
                options={districts.filter(
                  (option) => option.area_id === areaId
                )}
                renderInput={(params) => (
                  <TextField {...params} label="地區" variant="outlined" />
                )}
                value={districtItem}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BookingConditionBoxesContainer;
