// Actions
import { patientLogin } from "../../../redux/shared/patient/patientActions";

// Configs
import keyConfig from "../../../configs/keyConfig";

// Fetches
import { loginFetch } from "../../../fetches/patientFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useLoginPageStyles } from "../../../styles/pageStyles/loginPageStyles";

function LoginForm() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useLoginPageStyles();

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Text Fields
  const [fullNameEn, setFullNameEn] = useState("");
  const [hkid, setHkid] = useState("");

  // Events
  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "fullNameEn":
        setFullNameEn(value);
        break;
      case "hkid":
        setHkid(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onRegisterBtnClicked = async () => {
    // Navigate
    navigate("/patientRegister");
  };

  const onSubmitBtnClicked = async () => {
    let isError = false;

    if (!fullNameEn) {
      addToErrorFields("fullNameEn", "請先填寫 英文全名");
      isError = true;
    }

    if (!hkid) {
      addToErrorFields("hkid", "請先填寫 身分證編號");
      isError = true;
    }

    if (isError) {
      return;
    }

    login();
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  const updatePatientInfo = (patient, token) => {
    // Update Redux Store
    dispatch(patientLogin(token, patient.full_name_en, patient.id));

    // Local Storage
    localStorage.setItem(keyConfig.tokenKey, token);
  };

  // Functions - Queries
  const login = async () => {
    const results = await loginFetch(hkid, fullNameEn);

    if (results.token && results.patient) {
      const { patient, token } = results;

      updatePatientInfo(patient, token);

      // Navigate
      navigate("/createBooking");
    } else if (results.type && results.type === "Unregistered") {
      showFormAlert("warning", "此 身份證編號 尚未被登記，請先登記病人資料");
    } else {
      showFormAlert("error", "未能登入");
    }
  };

  return (
    <>
      <Typography align={"center"} variant="h6">
        預約系統
      </Typography>
      <div className={classes.loginInputsContainer}>
        <TextField
          autoComplete="current-hkid"
          error={checkIsFieldError("hkid")}
          fullWidth
          helperText={getErrorFieldMessage("hkid")}
          label="身分證編號"
          margin="dense"
          name="hkid"
          onChange={(event) => onInputFieldChange("hkid", event.target.value)}
          onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
          required
          variant="standard"
        />
        <TextField
          error={checkIsFieldError("fullNameEn")}
          fullWidth
          helperText={getErrorFieldMessage("fullNameEn")}
          label="英文全名"
          margin="dense"
          name="fullNameEn"
          onChange={(event) =>
            onInputFieldChange("fullNameEn", event.target.value)
          }
          onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
          required
          variant="standard"
        />
      </div>
      <div className={classes.loginSubmitContainer}>
        <Button fullWidth onClick={onSubmitBtnClicked} variant="contained">
          登入
        </Button>
      </div>
      {shouldShowFormAlert && (
        <div className={classes.alertContainer}>
          <Alert severity={formAlertType}>{formAlertText}</Alert>
        </div>
      )}
      <div className={classes.registerBtnContainer}>
        <Button fullWidth onClick={onRegisterBtnClicked} variant="text">
          尚未登記？請按此登記
        </Button>
      </div>
    </>
  );
}

export default LoginForm;
