// Configs
import stylesConfig from "../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const useLoginPageStyles = makeStyles((theme) =>
  createStyles({
    alertContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: 30,
    },
    loginContainer: {
      alignItems: "center",
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroudColorLight
          : stylesConfig.contentBackgroudColorDark,
      borderRadius: stylesConfig.borderRadiusLarge,
      boxShadow:
        theme.palette.mode === "light"
          ? stylesConfig.shadowLargeLight
          : stylesConfig.shadowLargeDark,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "30px 60px",
    },
    loginInputsContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: 30,
      marginTop: 25,
      maxWidth: 300,
      width: "60vw",
    },
    loginSubmitContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    registerBtnContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: stylesConfig.spacingBoxMarginBottomSmall,
    },
  })
);
