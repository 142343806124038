// Actions
import { onThemeChange } from "../../../redux/shared/theme/themeActions";

// Configs
import keyConfig from "../../../configs/keyConfig";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

function AppWrapper({ children }) {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Functions
  // Functions - Normal
  const getThemeModeFromStorage = () => {
    // Local Storage
    const themeModeVar = localStorage.getItem(keyConfig.themeKey);

    if (themeModeVar) {
      // Update Redux Store
      dispatch(onThemeChange(themeModeVar));
    }
  };

  // Life Cycle
  useEffect(() => {
    getThemeModeFromStorage();
  }, []);

  return <>{children}</>;
}

export default AppWrapper;
