// Material UI
// Components
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

// Styles
import {
  alertSnackbarStyles,
  alertSnackbarAlertStyles,
} from "../../styles/componentStyles/snackBarStyles/alertSnackbarStyles";

function AlertSnackbar(props) {
  const {
    // Constants
    horizontalPosition,
    verticalPosition,
    // Events
    onSnackbarClosed,
    // States
    showSnackbar,
    snackbarText,
    snackbarType,
  } = props;

  return (
    <>
      <Snackbar
        anchorOrigin={{
          horizontal: horizontalPosition,
          vertical: verticalPosition,
        }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={onSnackbarClosed}
        sx={alertSnackbarStyles}
      >
        <Alert
          onClose={onSnackbarClosed}
          severity={snackbarType}
          sx={alertSnackbarAlertStyles}
        >
          {snackbarText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AlertSnackbar;
