// Actions
import { patientLogout } from "../../../redux/shared/patient/patientActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Modals
import PatientActionModalPatientInfo from "./patientActionModalPatientInfo";

// Configs
import keyConfig from "../../../configs/keyConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getPatientBasicInfoByIdFetch } from "../../../fetches/patientFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/locale/zh-hk";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PatientActionModal(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
  } = props;

  // Redux Store
  const patientId = useSelector((state) => state.patient.id);
  const token = useSelector((state) => state.patient.token);

  // States
  // Data
  const [patientDetails, setPatientDetails] = useState(null);
  // Dialog
  const [patientActionDialogText, setPatientActionDialogText] = useState("");
  const [patientActionDialogType, setPatientActionDialogType] = useState(null);
  const [showPatientActionDialog, setShowPatientActionDialog] = useState(false);

  // Events
  // Events - Dialogs
  const onPatientActionDialogCanceled = () => {
    // Set States
    setShowPatientActionDialog(false);
  };

  const onPatientActionDialogConfirmed = () => {
    switch (patientActionDialogType) {
      case "Logout":
        // Update Redux Store
        dispatch(patientLogout());

        // Local Storage
        localStorage.removeItem(keyConfig.tokenKey);

        onModalClosed();
        break;
      default:
        break;
    }

    // Set States
    setShowPatientActionDialog(false);
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    displayPatientActionDialog("Logout");
  };

  // Functions
  // Functions - Normal
  const displayPatientActionDialog = (patientActionType) => {
    // Set States
    setPatientActionDialogType(patientActionType);

    switch (patientActionType) {
      case "Logout":
        setPatientActionDialogText("確認要 登出 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowPatientActionDialog(true);
  };

  // Functions = Queries
  const getPatientBasicInfoById = async () => {
    const results = await getPatientBasicInfoByIdFetch(token, patientId);

    if (results.patientBasicInfo) {
      results.patientBasicInfo.date_registered = moment(
        results.patientBasicInfo.date_registered
      ).format("YYYY年 MM月 DD日");
    }

    // Set States
    setPatientDetails(
      results.patientBasicInfo ? results.patientBasicInfo : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (patientId) {
      getPatientBasicInfoById();
    }
  }, [patientId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPatientActionDialogCanceled}
        onDialogConfirmed={onPatientActionDialogConfirmed}
        // States
        dialogText={patientActionDialogText}
        showDialog={showPatientActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            帳戶資料
          </Typography>
        </div>
      </div>
      {/* Content */}
      {patientDetails ? (
        <div>
          <PatientActionModalPatientInfo
            // States
            item={patientDetails}
          />
          <Grid
            className={classes.formContainer}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            container
            rowSpacing={1}
          >
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={formSubmitBtnStyles}
              >
                登出
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有帳戶資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PatientActionModal;
