export function onFormSubmitCountIncrease() {
  return {
    type: "ON_MEDICAL_RECORDS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_MEDICAL_RECORDS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
