export function patientLogin(token, fullNameEn, id) {
  return {
    type: "PATIENT_LOGIN",
    token,
    fullNameEn,
    id,
  };
}

export function patientLogout() {
  return {
    type: "PATIENT_LOGOUT",
  };
}
