// Actions
import { patientLogin } from "../redux/shared/patient/patientActions";

// Components
import LoginForm from "../components/forms/loginPage/loginForm";

// Configs
import keyConfig from "../configs/keyConfig";

// Fetches
import { autoLoginFetch } from "../fetches/patientFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useLoginPageStyles } from "../styles/pageStyles/loginPageStyles";

function LoginPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useLoginPageStyles();

  // States
  // Render
  const [isLoading, setIsLoading] = useState(true);

  // Funtions
  // Functions _ Normal
  const getPatientFromStorage = () => {
    // Local Storage
    const token = localStorage.getItem(keyConfig.tokenKey);

    if (token) {
      autoLogin(token);
    }

    // Set States
    setIsLoading(false);
  };

  const updatePatientInfo = (patient, token) => {
    // Update Redux Store
    dispatch(patientLogin(token, patient.full_name_en, patient.id));
  };

  // Funtions - Queries
  const autoLogin = async (token) => {
    const results = await autoLoginFetch(token);

    if (results.patient) {
      const { patient } = results;

      updatePatientInfo(patient, token);

      // Navigate
      navigate("/createBooking");
    } else if (results.isError) {
      // Local Storage
      localStorage.removeItem(keyConfig.tokenKey);
    }
  };

  // Life Cycle
  useEffect(() => {
    getPatientFromStorage();
  }, []);

  return (
    <div className={classes.loginContainer}>
      {isLoading ? (
        <Typography align={"center"} variant="h6">
          載入中...
        </Typography>
      ) : (
        <LoginForm />
      )}
    </div>
  );
}

export default LoginPage;
