// Configs
import stylesConfig from "../../configs/stylesConfig";

// Material UI
// Components
import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  listItemStyles,
  selectedListItemStyles,
} from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerListItem(props) {
  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  // Props
  const {
    // Render
    badgeContent,
    icon,
    itemKey,
    location,
    pathnamesArr,
    listItemText,
    // Events
    onDrawerItemClicked,
  } = props;

  return (
    <ListItem
      key={itemKey}
      button={!pathnamesArr.includes(location.pathname)}
      onClick={() => onDrawerItemClicked(itemKey)}
      sx={
        pathnamesArr.includes(location.pathname)
          ? {
              ...selectedListItemStyles,
              backgroundColor:
                themeMode === "light"
                  ? stylesConfig.contentBackgroudColorLight
                  : stylesConfig.contentBackgroudColorDark,
              boxShadow:
                themeMode === "light"
                  ? stylesConfig.shadowTinyLight
                  : stylesConfig.shadowTinyDark,
            }
          : listItemStyles
      }
    >
      <Badge
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        badgeContent={badgeContent && badgeContent > 0 ? badgeContent : 0}
        color="primary"
      >
        <ListItemIcon>{icon}</ListItemIcon>
      </Badge>
      <ListItemText primary={listItemText} />
    </ListItem>
  );
}

export default LeftDrawerListItem;
