// Material UI
// Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function AlertDialog(props) {
  const {
    // Events
    onDialogClosed,
    onDialogConfirmed,
    // States
    dialogText,
    showDialog,
  } = props;

  // Events
  const onDialogKeyPress = (key) => {
    if (key === "Enter") {
      onDialogConfirmed();
    }
  };

  return (
    <>
      <Dialog
        onKeyPress={(event) => onDialogKeyPress(event.key)}
        open={showDialog}
        onClose={onDialogClosed}
      >
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClosed}>取消</Button>
          <Button onClick={onDialogConfirmed} autoFocus>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AlertDialog;
