// Actions
import {
  onAreaChange,
  onAreaItemChange,
  onDateChange,
  onDayChange,
  onDistrictChange,
  onDistrictItemChange,
  onShopChange,
  onTherapyChange,
  onTherapyItemChange,
  onTimeSlotChange,
} from "../../../redux/pages/createBookingPage/createBookingPageActions";

// Components
// Boxes
import BookingConditionBoxesContainer from "./bookingConditionBoxesContainer";
import DaySuggestionBoxesContainer from "./daySuggestionBoxesContainer";
import ShopSuggestionBoxesContainer from "./shopSuggestionBoxesContainer";
import TimeSlotSuggestionBoxesContainer from "./timeSlotSuggestionBoxesContainer";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Snackbars
import AlertSnackbar from "../../snackbar/alertSnackbar";
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  createBookingFetch,
  editBookingFetch,
} from "../../../fetches/bookingFetches";
import { getRoomSuggestionsFetch } from "../../../fetches/roomFetches";

// Helper Functions
import { randomNumWithRange } from "../../../helperFunctions/randomNumWithRange";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function ContentContainer(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Props
  const {
    // States
    areaIdParam,
    bookingIdParam,
    districtIdParam,
    therapyIdParam,
  } = props;

  // Redux Store
  const date = useSelector((state) => state.createBookingPage.date);
  const dayId = useSelector((state) => state.createBookingPage.dayId);
  const districtId = useSelector((state) => state.createBookingPage.districtId);
  const patientId = useSelector((state) => state.patient.id);
  const shopId = useSelector((state) => state.createBookingPage.shopId);
  const therapyId = useSelector((state) => state.createBookingPage.therapyId);
  const timeSlotId = useSelector((state) => state.createBookingPage.timeSlotId);
  const token = useSelector((state) => state.patient.token);

  // States
  // Dialog
  const [bookingActionDialogText, setBookingActionDialogText] = useState("");
  const [bookingActionDialogType, setBookingActionDialogType] = useState(null);
  const [showBookingActionDialog, setShowBookingActionDialog] = useState(false);
  // Snackbar
  const [showBookingActionSnackbar, setShowBookingActionSnackbar] =
    useState(false);
  const [bookingActionSnackbarText, setBookingActionSnackbarText] =
    useState("");
  const [bookingActionSnackbarType, setBookingActionSnackbarType] =
    useState("success");

  // Handle States
  const shouldShowShopSuggestions = therapyId && districtId;
  const shouldShowDaySuggestions = shouldShowShopSuggestions && shopId;
  const shouldShowTimeSlotSuggestions = shouldShowDaySuggestions && dayId;

  // Events
  // Events - Dialogs
  const onBookingActionDialogCanceled = () => {
    // Set States
    setShowBookingActionDialog(false);
  };

  const onBookingActionDialogConfirmed = () => {
    switch (bookingActionDialogType) {
      case "CreateBooking":
        createBooking();
        break;
      case "EditBooking":
        editBooking();
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(false);
  };

  // Events - Snackbar
  const onBookingActionSnackbarClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Set States
    setShowBookingActionSnackbar(false);
  };

  // Functions
  // Functions - Normal
  const clearAll = () => {
    // Update Redux Store
    dispatch(onAreaChange(null));
    dispatch(onAreaItemChange(null));
    dispatch(onDateChange(null));
    dispatch(onDayChange(null));
    dispatch(onDistrictChange(null));
    dispatch(onDistrictItemChange(null));
    dispatch(onShopChange(null));
    dispatch(onTherapyChange(null));
    dispatch(onTherapyItemChange(null));
    dispatch(onTimeSlotChange(null));
  };

  const displayBookingActionDialog = (bookingActionType) => {
    // Set States
    setBookingActionDialogType(bookingActionType);

    switch (bookingActionType) {
      case "CreateBooking":
        setBookingActionDialogText("確認要新增 預約 嗎？");
        break;
      case "EditBooking":
        setBookingActionDialogText("確認要修改 預約 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(true);
  };

  const displayBookingActionSnackBar = (bookingActionMessage) => {
    // Set States
    setBookingActionSnackbarText(bookingActionMessage);
    setBookingActionSnackbarType("warning");

    setShowBookingActionSnackbar(true);
  };

  // Functions - Mutations
  const createBooking = async () => {
    const roomId = await getRoomSuggestions();

    if (!roomId) {
      displayBookingActionSnackBar("未能提交，請再試一次");
    }

    const results = await createBookingFetch(
      token,
      roomId,
      patientId,
      therapyId,
      date,
      timeSlotId
    );

    if (results.success) {
      clearAll();

      // Navigate
      navigate("/bookingRecords");
    } else if (
      results.isValidate !== undefined &&
      results.isValidate === false
    ) {
      displayBookingActionSnackBar(results.message);
    }
  };

  const editBooking = async () => {
    const roomId = await getRoomSuggestions();

    if (!roomId) {
      displayBookingActionSnackBar("未能提交，請再試一次");
    }

    const results = await editBookingFetch(
      token,
      bookingIdParam ? bookingIdParam : null,
      roomId,
      therapyId,
      date,
      timeSlotId
    );

    if (results.success) {
      clearAll();

      // Navigate
      navigate("/bookingRecords");
    } else if (
      results.isValidate !== undefined &&
      results.isValidate === false
    ) {
      displayBookingActionSnackBar(results.message);
    }
  };

  // Functions - Queries
  const getRoomSuggestions = async () => {
    const results = await getRoomSuggestionsFetch(
      token,
      bookingIdParam ? bookingIdParam : null,
      shopId,
      therapyId,
      timeSlotId
    );

    if (results.rooms) {
      const { rooms } = results;

      const roomIndex = randomNumWithRange(0, rooms.length - 1);

      return rooms[roomIndex].id;
    }

    return null;
  };

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onBookingActionDialogCanceled}
        onDialogConfirmed={onBookingActionDialogConfirmed}
        // States
        dialogText={bookingActionDialogText}
        showDialog={showBookingActionDialog}
      />
      {/* Snackbar */}
      <AlertSnackbar
        // Constants
        horizontalPosition={"left"}
        verticalPosition={"bottom"}
        // Events
        onSnackbarClosed={onBookingActionSnackbarClosed}
        // States
        showSnackbar={showBookingActionSnackbar}
        snackbarText={bookingActionSnackbarText}
        snackbarType={bookingActionSnackbarType}
      />
      <BookingConditionBoxesContainer
        // States
        areaIdParam={areaIdParam}
        districtIdParam={districtIdParam}
        therapyIdParam={therapyIdParam}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      {shouldShowShopSuggestions && (
        <>
          <ShopSuggestionBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </>
      )}
      {shouldShowDaySuggestions && (
        <>
          <DaySuggestionBoxesContainer
            // States
            bookingIdParam={bookingIdParam}
          />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </>
      )}
      {shouldShowTimeSlotSuggestions && (
        <>
          <TimeSlotSuggestionBoxesContainer
            // States
            bookingIdParam={bookingIdParam}
            // Functions
            displayBookingActionDialog={displayBookingActionDialog}
          />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </>
      )}
    </>
  );
}

export default ContentContainer;
