// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useHorizontalItemBoxStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxStyles";

function DaySuggestionBox(props) {
  // Hooks
  // Styles
  const classes = useHorizontalItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id, item.date)}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.blueTextColor
                : null
            }
            variant="body1"
          >
            {item.date_display}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {item.weekday_name_ch_full}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default DaySuggestionBox;
